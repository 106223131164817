import { extendTheme, type ThemeConfig } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
import type { StyleFunctionProps } from '@chakra-ui/styled-system';

const themeOverrides: ThemeConfig = extendTheme({
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        color: mode('gray.800', 'whiteAlpha.900')(props),
        bg: mode('white', 'gray.800')(props),
      },
      a: {
        color: mode('cyan.700', 'cyan.300')(props),
        _hover: {
          color: mode('cyan.800', 'cyan.400')(props),
        },
      },
      h1: {
        color: mode('cyan.600', 'cyan.300')(props),
      },
      h2: {
        color: mode('cyan.600', 'cyan.300')(props),
      },
      h3: {
        color: mode('cyan.600', 'cyan.300')(props),
      },
    }),
  },
  config: {
    cssVarPrefix: 'ad',
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  fonts: {
    body: 'Source Sans Pro, serif',
    heading: 'Montserrat, sans-serif',
  },
});

export default themeOverrides;
